import {
    ActivityReadDTO,
    DimrVersionReadDTO,
    DimrVersionUpdateDTO,
    LocationDTO,
    PersonRichReadDTO,
    RpAssessmentReadDTO,
} from '../../../api/dto';

const checkOperDosimetryMandatory = (activity: ActivityReadDTO): boolean => {
    return (
        activity.locations
            ?.flatMap((location) => (location.location ? location.location.operDosimetryMandatory : []))
            .some((operDosimetryMandatory) => operDosimetryMandatory === true) ?? false
    );
};
export const makeDefaultRpAssessmentValuesFromLinkedActivity = (
    linkedActivity: ActivityReadDTO
): RpAssessmentReadDTO => {
    return {
        activity: linkedActivity,
        avgEstDoseRate: null,
        contaminatingWorks: linkedActivity.contaminatingWorks ?? false,
        doseRateAlarmThreshold: 2000,
        equipmentJobCode: null,
        estCollectiveDose: null,
        generalJobCode: null,
        highestAreaClassification: null, //TODO in IMPACT-1548
        id: null as any,
        individualDoseAlarm: 50,
        maxEstAirborneCont: null,
        maxEstDoseRate: null,
        maxEstIndividualDose: null,
        maxEstSurfaceCont: null,
        maxIndividualWorkTime: linkedActivity.maxIndWorkTime,
        operDosimetryMandatory: checkOperDosimetryMandatory(linkedActivity),
        historicalRadiologicalConditions: [],
        rpAssessmentPcrs: [],
        rpPresence: null,
        historicalRpRecommendations: [],
        rssoRpe: null,
        totalCollectiveWorkTime: linkedActivity.totalColWorkTime,
        valuesForcedManual: false,
        wdpWorkSteps: [],
        locations:
            linkedActivity?.locations
                ?.map(({ location }) => location)
                .filter((location): location is LocationDTO => location !== null) ?? [],
        actualCollectiveDose: null,
        actualDosesDate: null,
        actualMaxIndividualDose: null,
        rpComments: null,
        rsoComments: null,
        attachments: [],
        extremityDosimetryMandatory: false,
    };
};
export const makeDimrDefaultValuesFromLinkedActivity = (
    linkedActivity: ActivityReadDTO,
    currentUser: PersonRichReadDTO
) => {
    return {
        title: linkedActivity.title,
        owner: linkedActivity.responsible,
        mainFacility: linkedActivity.facility,
        workDosePlanningVersions: [],
        alaraLevelForced: false,
        bypassWorkflow: false,
        pcrNotification: false,
        rpAssessments: [makeDefaultRpAssessmentValuesFromLinkedActivity(linkedActivity)],
    } satisfies Partial<DimrVersionReadDTO>;
};

export const mapDimrToUpdateDTO = (
    dimr: any,
    isCreation: boolean = false
): DimrVersionUpdateDTO & DimrVersionReadDTO => {
    const { activity, ...dimrWithoutActivity } = dimr as any;
    return isCreation ? dimr : dimrWithoutActivity;
};
