import { IntlShape } from 'react-intl';
import {
    ContractNimReadDTO,
    CsamSensorReadDTO,
    EgroupDTO,
    EquipmentReadDTO,
    FirePermitReadDTO,
    IS37ReadDTO,
    LocationDTO,
    OrgUnitReadDTO,
    PersonReadDTO,
    SupplierReadDTO,
    SystemBsSrcReadDTO,
} from '../api/dto';
import { translateValue } from './language';

export const getPersonLabel = (person: PersonReadDTO): string => person.searchLabel;

export const getOrgUnitLabel = (orgUnit: OrgUnitReadDTO): string => orgUnit.code || '';

export const getLovLabel = (value: ReturnType<typeof translateValue>): string => value.label;

export const getCsamSensorLabel = (value: CsamSensorReadDTO): string => value.searchLabel || '';

export const getContractLabel = (value: ContractNimReadDTO): string =>
    value.contractNumber + ' - ' + value.employer?.name + ' - ' + value.cernContractResponsible?.searchLabel;

export const getLocationLabel = (location: LocationDTO): string => location.impactName;
export const getCompanyLabel = (company: SupplierReadDTO): string => company.name;

export const getEgroupLabel = (egroup: EgroupDTO): string => egroup.name || '';
export const getEquipmentLabel = (equipment: EquipmentReadDTO): string =>
    `${equipment.code}${equipment.description ? ` (${equipment.description})` : ''}`;

export const getFirePermitLabel = (firePermit: FirePermitReadDTO, intl: IntlShape): string =>
    intl.formatMessage({ id: 'firePermit.titleExisting' }, { firePermitId: firePermit.id }); // TODO add activity title?

export const getSupplierLabel = (supplier: SupplierReadDTO): string => supplier.name ?? '';
export const getSupplierCodeLabel = (supplier: SupplierReadDTO): string => supplier.code ?? '';

export const getSystemLabel = (system: SystemBsSrcReadDTO): string => system.fullname ?? '';

export const getIs37Label = (is37: IS37ReadDTO, intl: IntlShape): string =>
    intl.formatMessage({ id: 'is37.titleExisting' }, { is37Id: is37.id });
