import { Lock, NoEncryptionGmailerrorred, Settings } from '@mui/icons-material';
import { Alert } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { getLockoutTypes } from '../../../api/documents/lockouts';
import { getCryoLockTypes, getWorkingTimes } from '../../../api/values';
import { TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { cryoLockoutEquipmentSchema, equipmentSchema } from '../equipment';
import { locationSchema } from '../location';
import { valueSchema } from '../value';

const NonFullLockoutWarning: React.FC = () => {
    return (
        <Alert severity="warning">
            <FormattedMessage id="lockout.reminder" />
        </Alert>
    );
};

const LocationMismatchWarning: React.FC = () => {
    return (
        <Alert severity="warning">
            <FormattedMessage id="lockout.location.mismatch.warning" />
        </Alert>
    );
};

export const lockoutFormSchema = (
    intl: IntlShape,
    showPerformPanel: boolean,
    showRemovePanel: boolean,
    showSafetyWarning: boolean,
    showLocationMismatchWarning: boolean
): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                    },
                },
                elements: [
                    ...(showSafetyWarning
                        ? [
                              {
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: NonFullLockoutWarning,
                                  } satisfies TFormElement,
                              },
                          ]
                        : []),
                    {
                        element: locationSchema({
                            label: intl.formatMessage({ id: 'document.field.locations' }),
                            path: 'lockoutLocations',
                            multiple: true,
                        }),
                    },
                    ...(showLocationMismatchWarning
                        ? [
                              {
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: LocationMismatchWarning,
                                  } satisfies TFormElement,
                              },
                          ]
                        : []),
                    {
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'lockout.field.type' }),
                                path: 'lockoutType',
                                fetchOptions: getLockoutTypes,
                                disabled: true,
                            },
                            intl.locale
                        ),
                    },
                    {
                        element: cryoLockoutEquipmentSchema({
                            label: intl.formatMessage({ id: 'document.field.equipment' }),
                            path: 'lockoutCryoEquipments',
                            multiple: true,
                        }),
                    },
                    {
                        element: equipmentSchema({
                            label: intl.formatMessage({ id: 'lockout.field.equipmentWorkedOn' }),
                            path: 'equipmentWorkedOn',
                            multiple: true,
                            active: true,
                        }),
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'lockout.field.equipmentWorkedOn.details' }),
                            path: 'electricalEquipmentText',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.DATETIME,
                                        label: intl.formatMessage({ id: 'lockout.field.requestStart' }),
                                        path: 'requestStartDate',
                                        typeProperties: {
                                            minDate: new Date(),
                                        },
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.DATETIME,
                                        label: intl.formatMessage({ id: 'lockout.field.requestEnd' }),
                                        path: 'requestEndDate',
                                        typeProperties: {
                                            minDate: new Date(),
                                        },
                                    },
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'lockout.field.comments' }),
                            path: 'comments',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                ],
            },
        },
        ...(showPerformPanel
            ? [
                  {
                      type: UiSchemaType.PANEL,
                      label: intl.formatMessage({ id: 'lockout.panel.performingLockout' }),
                      typeProperties: {
                          icon: <Lock color="action" />,
                      },
                      element: {
                          type: UiSchemaType.LAYOUT_RESPONSIVE,
                          elements: [
                              {
                                  element: {
                                      type: UiSchemaType.CHECKBOX,
                                      label: intl.formatMessage({ id: 'lockout.field.full' }),
                                      path: 'full',
                                  },
                              },
                              {
                                  element: valueSchema(
                                      {
                                          path: 'lockType',
                                          label: intl.formatMessage({ id: 'lockout.field.cryoLockType' }),
                                          fetchOptions: getCryoLockTypes,
                                      },
                                      intl.locale
                                  ),
                              },
                              {
                                  element: {
                                      type: UiSchemaType.LAYOUT_RESPONSIVE,
                                      typeProperties: {
                                          direction: {
                                              xs: 'column',
                                              sm: 'row',
                                          },
                                      },
                                      elements: [
                                          {
                                              element: {
                                                  type: UiSchemaType.INPUT,
                                                  label: intl.formatMessage({ id: 'lockout.field.procedure' }),
                                                  path: 'lockEdmsProcedure',
                                              },
                                          },
                                          {
                                              element: {
                                                  type: UiSchemaType.INPUT,
                                                  label: intl.formatMessage({ id: 'lockout.field.otherProcedure' }),
                                                  path: 'lockOtherProcedure',
                                              },
                                          },
                                      ],
                                  },
                              },
                              {
                                  element: equipmentSchema({
                                      label: intl.formatMessage({ id: 'lockout.field.equipmentToLock' }),
                                      path: 'equipmentToLock',
                                      multiple: true,
                                      active: true,
                                  }),
                              },
                              {
                                  element: {
                                      type: UiSchemaType.INPUT,
                                      label: intl.formatMessage({ id: 'lockout.field.equipmentToLock.details' }),
                                      path: 'powerConvertersText',
                                  },
                              },
                              {
                                  element: {
                                      type: UiSchemaType.LAYOUT_RESPONSIVE,
                                      typeProperties: {
                                          direction: {
                                              xs: 'column',
                                              sm: 'row',
                                          },
                                      },
                                      elements: [
                                          {
                                              element: {
                                                  type: UiSchemaType.INPUT,
                                                  label: intl.formatMessage({ id: 'lockout.field.lockout1level' }),
                                                  path: 'lockout1level',
                                              },
                                          },
                                          {
                                              element: {
                                                  type: UiSchemaType.INPUT,
                                                  label: intl.formatMessage({ id: 'lockout.field.lockout2level' }),
                                                  path: 'lockout2level',
                                              },
                                          },
                                      ],
                                  },
                              },
                              {
                                  element: valueSchema(
                                      {
                                          path: 'grantedWorkingTime',
                                          label: intl.formatMessage({ id: 'lockout.field.grantedWorkingTime' }),
                                          fetchOptions: getWorkingTimes,
                                      },
                                      intl.locale
                                  ),
                              },
                              {
                                  element: {
                                      type: UiSchemaType.LAYOUT_RESPONSIVE,
                                      typeProperties: {
                                          direction: {
                                              xs: 'column',
                                              sm: 'row',
                                          },
                                      },
                                      elements: [
                                          {
                                              element: {
                                                  type: UiSchemaType.TIME,
                                                  label: intl.formatMessage({ id: 'lockout.field.grantedStart' }),
                                                  path: 'grantedWorkingTimeStart',
                                              },
                                          },
                                          {
                                              element: {
                                                  type: UiSchemaType.TIME,
                                                  label: intl.formatMessage({ id: 'lockout.field.grantedEnd' }),
                                                  path: 'grantedWorkingTimeEnd',
                                              },
                                          },
                                      ],
                                  },
                              },

                              {
                                  element: {
                                      type: UiSchemaType.DATETIME,
                                      label: intl.formatMessage({ id: 'lockout.field.executionDate' }),
                                      path: 'lockExecutionDate',
                                      typeProperties: {
                                          maxDate: new Date(),
                                      },
                                  },
                              },
                              {
                                  element: {
                                      type: UiSchemaType.INPUT,
                                      label: intl.formatMessage({ id: 'lockout.field.comments' }),
                                      path: 'lockoutComments',
                                      typeProperties: {
                                          multiline: true,
                                      },
                                  },
                              },
                          ],
                      },
                  } satisfies TFormElement,
              ]
            : []),
        ...(showRemovePanel
            ? [
                  {
                      type: UiSchemaType.PANEL,
                      label: intl.formatMessage({ id: 'lockout.panel.removingLockout' }),
                      typeProperties: {
                          icon: <NoEncryptionGmailerrorred color="action" />,
                      },
                      element: {
                          type: UiSchemaType.LAYOUT_RESPONSIVE,
                          elements: [
                              {
                                  element: {
                                      type: UiSchemaType.LAYOUT_RESPONSIVE,
                                      typeProperties: {
                                          direction: {
                                              xs: 'column',
                                              sm: 'row',
                                          },
                                      },
                                      elements: [
                                          {
                                              element: {
                                                  type: UiSchemaType.INPUT,
                                                  label: intl.formatMessage({ id: 'lockout.field.procedure' }),
                                                  path: 'unlockEdmsProcedure',
                                              },
                                          },
                                          {
                                              element: {
                                                  type: UiSchemaType.INPUT,
                                                  label: intl.formatMessage({ id: 'lockout.field.otherProcedure' }),
                                                  path: 'unlockOtherProcedure',
                                              },
                                          },
                                      ],
                                  },
                              },
                              {
                                  element: {
                                      type: UiSchemaType.DATETIME,
                                      label: intl.formatMessage({ id: 'lockout.field.executionDate' }),
                                      path: 'unlockExecutionDate',
                                      typeProperties: {
                                          maxDate: new Date(),
                                      },
                                  },
                              },
                              {
                                  element: {
                                      type: UiSchemaType.INPUT,
                                      label: intl.formatMessage({ id: 'lockout.field.comments' }),
                                      path: 'unlockExecutionComments',
                                      typeProperties: {
                                          multiline: true,
                                      },
                                  },
                              },
                          ],
                      },
                  } satisfies TFormElement,
              ]
            : []),
    ],
});
