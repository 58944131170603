import { Box, LinearProgress, Paper, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useDeviceType } from '../../hooks/useDeviceType';
import { DocumentDrawerContext, DocumentDrawerStructure } from './DocumentDrawerStructure';
import { DocumentPageContainer } from './DocumentPageContainer';

interface DocumentPageStructureProps {
    Header: React.ReactNode;
    Body: React.ReactNode;
    Drawer?: React.ReactNode;
    isLoading?: boolean;
    noContainer?: boolean;
}

export const DocumentPageStructure: React.FC<DocumentPageStructureProps> = ({
    Header,
    Body,
    Drawer,
    isLoading,
    noContainer,
}) => {
    const { isMobile } = useDeviceType();
    const theme = useTheme();
    const { width, isDrawerOpen } = useContext(DocumentDrawerContext)!;
    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                height: '100%',
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: !!Drawer && !isMobile && isDrawerOpen ? `${width}px` : 0,
            }}
        >
            <Box
                sx={{
                    top: 0,
                    position: 'sticky',
                    zIndex: 3,
                }}
            >
                <Paper
                    elevation={1}
                    sx={{
                        padding: 2,
                        pt: 1,
                        px: 0,
                    }}
                    square
                >
                    {Header}
                </Paper>
                {!!isLoading && <LinearProgress sx={{ mb: -0.5 }} />}
            </Box>
            <Box
                sx={{
                    overflow: 'auto',
                    scrollbarGutter: 'stable both-edges',
                }}
            >
                {!noContainer ? <DocumentPageContainer drawer={!!Drawer}>{Body}</DocumentPageContainer> : Body}
            </Box>
            {Drawer ? <DocumentDrawerStructure Content={Drawer} /> : null}
            <Box
                sx={{
                    overflow: 'auto',
                    scrollbarGutter: 'stable both-edges',
                }}
            ></Box>
        </Box>
    );
};
