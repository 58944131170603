import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getDimrByVersionId } from '../../../api/documents/dimrs';
import { getWdpRules, getWdpVersion, getWdpVersionLatest } from '../../../api/documents/wdps';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { WdpForm } from '../../../forms/documents/WdpForm';
import { getRoute, ROUTES } from '../../../routes/routes';
import { WdpPageVariant } from './WdpPageVariant';

interface WdpPageProps {
    variant: WdpPageVariant;
}

export const WdpPage: React.FC<WdpPageProps> = ({ variant }) => {
    const [spreadsheetFullscreen, setSpreadsheetFullscreen] = useState(false);
    const intl = useIntl();
    const { id: wdpId, version: wdpVersionNumber } = useParams();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        data: wdpData,
        isLoading: wdpDataLoading,
        isError: wdpDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.WDP, wdpId, wdpVersionNumber],
        () =>
            wdpVersionNumber !== undefined
                ? getWdpVersion({ id: String(wdpId), versionNumber: parseInt(wdpVersionNumber) })
                : getWdpVersionLatest({ id: String(wdpId) }),
        {
            enabled: wdpId !== undefined,
        }
    );
    const dimrVersionId = variant === WdpPageVariant.CREATE ? params.get('dimr') : wdpData?.dimr?.id;
    useEffect(() => {
        if (wdpData) {
            if (variant === WdpPageVariant.READ) {
                navigate(
                    getRoute({
                        path: ROUTES.wdp.view,
                        params: { id: wdpData.wdpMasterId, version: wdpData.versionNumber },
                    }),
                    {
                        replace: true,
                    }
                );
            }
        }
    }, [wdpData, navigate]);
    const {
        data: dimrData,
        isLoading: isDimrDataLoading,
        isError: dimrDataFailed,
    } = useQuery(['dimr', dimrVersionId], () => getDimrByVersionId({ id: String(dimrVersionId) }), {
        enabled: dimrVersionId != null,
    });
    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.WDP, wdpId, wdpVersionNumber, 'rules'], () => getWdpRules());
    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if ((wdpDataLoading && wdpId !== undefined) || (dimrVersionId != null && isDimrDataLoading) || isRulesDataLoading) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.WDP} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (wdpDataFailed || dimrDataFailed || rulesDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="wdp.error.document" />
                <ImpactHelmet documentType={DocumentType.WDP} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        variant === WdpPageVariant.CREATE ||
        ((variant === WdpPageVariant.READ || variant === WdpPageVariant.CLONE) && wdpData)
    ) {
        return (
            <WdpForm
                key={variant}
                data={
                    variant === WdpPageVariant.CREATE ? { variant, dimrData: dimrData } : { variant, wdpData: wdpData! }
                }
                refreshData={refreshData}
                rules={rulesData}
                spreadsheetFullscreen={spreadsheetFullscreen}
                setSpreadsheetFullscreen={setSpreadsheetFullscreen}
            />
        );
    } else {
        return <Fragment />;
    }
};
