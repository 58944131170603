import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { CommentDTO } from '../../api/dto';
import { stringAvatar } from '../../lib/avatar';
import { formatDateTime } from '../../lib/date';
import { translateTask, translateTaskType } from '../../lib/language';
import { sanitizeRichText } from '../../lib/sanitizeHtml';

type CommentListItemProps = Parameters<typeof ListItem>[0] & {
    comment: CommentDTO;
};

export const CommentListItem: React.FC<CommentListItemProps> = ({ comment, ...rest }) => {
    const intl = useIntl();
    return (
        <ListItem {...rest} sx={{ p: 0, ...rest.sx }}>
            <ListItemAvatar>
                <Avatar {...stringAvatar(comment.createdBy)} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Stack direction={{ md: 'column', lg: 'row' }} justifyContent="space-between">
                        <Stack>
                            <Typography>{comment.createdBy.searchLabel}</Typography>
                            {!!comment.task && (
                                <Typography variant="caption">
                                    {translateTask(comment.task, intl.locale).assignmentLabel}
                                </Typography>
                            )}
                        </Stack>
                        <Typography variant="caption">
                            {!!comment.task &&
                                intl.formatMessage(
                                    { id: 'document.comment.task' },
                                    { task: translateTaskType(comment.task.type, intl.locale).typeName }
                                ) + ' · '}
                            {intl.formatMessage(
                                { id: 'document.comment.postedOn' },
                                { date: formatDateTime(comment.createdDate, intl) }
                            )}
                        </Typography>
                    </Stack>
                }
                secondary={
                    comment.richText ? (
                        <Box component="span" dangerouslySetInnerHTML={{ __html: sanitizeRichText(comment.text) }} />
                    ) : (
                        <Box component="span" style={{ whiteSpace: 'pre-line' }}>
                            {comment.text}
                        </Box>
                    )
                }
                sx={{ my: 0 }}
            />
        </ListItem>
    );
};
