import { Send } from '@mui/icons-material';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { addComment } from '../../api/task';

interface CommentInputProps {
    taskId: string;
    onSubmit: () => void;
    text: string;
    setText: (newText: string) => void;
    disabled: boolean;
}

export const CommentInput: React.FC<CommentInputProps> = ({ taskId, onSubmit, text, setText, disabled }) => {
    const intl = useIntl();

    const isTextEmpty = useMemo(() => !text.trim(), [text]);

    const handleTextEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };
    const { isLoading, mutate: mutateAddComment } = useMutation(addComment, {
        onSuccess: () => onSubmit(),
        onError: () => toast.error(intl.formatMessage({ id: 'common.error' })),
    });
    const submitComment = () => {
        if (!isTextEmpty) {
            mutateAddComment({ data: { comment: text }, taskId });
        }
    };
    return (
        <TextField
            value={text}
            size="small"
            fullWidth
            multiline
            maxRows={4}
            placeholder={intl.formatMessage({ id: 'document.comment.inputPlaceholder' })}
            onChange={handleTextEdit}
            onKeyDown={(e) => {
                if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                    submitComment();
                }
            }}
            disabled={isLoading || disabled}
            InputProps={{
                endAdornment: (
                    <Tooltip title={intl.formatMessage({ id: 'document.comment.publish' })}>
                        <IconButton
                            color="primary"
                            disabled={isTextEmpty || isLoading || disabled}
                            onClick={submitComment}
                        >
                            <Send />
                        </IconButton>
                    </Tooltip>
                ),
            }}
        />
    );
};
