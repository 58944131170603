import { Chat } from '@mui/icons-material';
import { Alert, Box, Collapse, LinearProgress, List, Stack, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentType, TaskDTO, TaskType } from '../../api/dto';
import { getDocumentComments } from '../../api/task';
import { DefaultAccordion } from '../DefaultAccordion';
import { CommentInput } from './CommentInput';
import { CommentListItem } from './CommentListItem';

interface CommentListProps {
    documentTypes: DocumentType[];
    documentId: number;
    currentTask: TaskDTO | null;
    disabled: boolean;
}
export const CommentList: React.FC<CommentListProps> = ({ documentId, documentTypes, currentTask, disabled }) => {
    const intl = useIntl();
    const queryClient = useQueryClient();
    const [text, setText] = useState('');
    const {
        isLoading,
        isError,
        data: commentsData,
    } = useQuery(
        ['comments', documentTypes.join(','), documentId],
        () => {
            return getDocumentComments({
                documentId: documentId,
                documentTypes: documentTypes,
            });
        },
        {
            onSuccess: () => setText(''),
            onError: () => setText(''),
        }
    );
    const reloadComments = async () => {
        await queryClient.invalidateQueries(['comments', documentTypes.join(','), documentId]);
    };
    const commentTaskId = !!currentTask && currentTask.type.type === TaskType.ADD_COMMENT ? currentTask.id : null;

    // Trick to enable the transitions when comments are added
    const [commentsLength, setCommentsLength] = useState(Number.MAX_SAFE_INTEGER);
    useEffect(() => commentsData && setCommentsLength(commentsData.length), [commentsData]);

    return (
        <Box sx={{ mt: 2 }}>
            <DefaultAccordion
                title={intl.formatMessage({ id: 'document.panel.comments' })}
                icon={<Chat color="action" />}
            >
                {isLoading && <LinearProgress />}
                <Stack direction="column" spacing={2}>
                    {isError ? (
                        <Alert severity="error">
                            <FormattedMessage id="common.error" />
                        </Alert>
                    ) : isLoading ? (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography sx={{ m: 2, mb: 0 }}>
                                <FormattedMessage id="document.comment.loading" />
                            </Typography>
                        </Box>
                    ) : !commentsData || commentsData.length < 1 ? (
                        commentTaskId === null ? (
                            <Alert severity="info">
                                <FormattedMessage id="document.comment.alertEmpty" />
                            </Alert>
                        ) : null
                    ) : (
                        <List sx={{ p: 0 }}>
                            {commentsData.map((comment, idx) => (
                                <Collapse key={idx} in={idx < commentsLength}>
                                    <CommentListItem
                                        comment={comment}
                                        sx={{ pb: idx + 1 < commentsData.length ? 2 : 0 }}
                                    />
                                </Collapse>
                            ))}
                        </List>
                    )}
                    {commentTaskId !== null && (
                        <CommentInput
                            taskId={commentTaskId}
                            onSubmit={reloadComments}
                            text={text}
                            setText={setText}
                            disabled={isLoading || disabled}
                        />
                    )}
                </Stack>
            </DefaultAccordion>
        </Box>
    );
};
