import { NotificationsOff } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { IS37ChangeRequestReadDTO, IS37GridReadDTO, IS37ReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { formatDateTime } from '../date';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getIs37ChangeRequestStatus, getIs37Status } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const Is37Icon = NotificationsOff;

export const getIs37Information = (is37: IS37GridReadDTO | IS37ReadDTO, intl: IntlShape): EntityInformation => {
    return {
        url: getRoute({ path: ROUTES.is37.view, params: { id: is37.id } }),
        name: intl.formatMessage({ id: 'is37.titleExisting' }, { is37Id: is37.id }),
        title: 'activity' in is37 ? is37.activity.title : null,
        status: getIs37Status(is37, intl),
        creation: is37,
        details: [
            ...('alarmType' in is37 && is37.alarmType
                ? [
                      {
                          labelKey: 'is37.field.alarmType' as const,
                          value: getLovLabel(translateValue(is37.alarmType.type, intl.locale)),
                          type: true,
                      },
                  ]
                : []),
            {
                labelKey: 'is37.field.startDate',
                value: is37.startDate ? formatDateTime(is37.startDate, intl) : '',
            },
            {
                labelKey: 'is37.field.endDate',
                value: is37.endDate ? formatDateTime(is37.endDate, intl) : '',
            },
            {
                labelKey: 'document.field.responsible',
                value: is37.responsible?.searchLabel ?? '',
                italic: true,
            },
        ],
    };
};

export const getIs37ChangeRequestInformation = (
    is37ChangeRequest: IS37ChangeRequestReadDTO,
    intl: IntlShape
): EntityInformation => {
    const formatOptionalDate = (date: string | null): string | null =>
        date !== null ? formatDateTime(date, intl) : null;
    return {
        url: null,
        name: intl.formatMessage({ id: 'is37.changeRequest.titleExisting' }, { changeRequestId: is37ChangeRequest.id }),
        title: null,
        status: getIs37ChangeRequestStatus(is37ChangeRequest, intl),
        creation: is37ChangeRequest,
        details: [
            {
                labelKey: 'is37.field.startDate',
                value: formatOptionalDate(is37ChangeRequest.newStartDate),
            },
            {
                labelKey: 'is37.field.endDate',
                value: formatOptionalDate(is37ChangeRequest.newEndDate),
            },
            ...((is37ChangeRequest.newRecommissioningStart !== null || is37ChangeRequest.newRecommissioningEnd !== null
                ? [
                      {
                          labelKey: 'is37.field.recommissioningStart',
                          value: formatOptionalDate(is37ChangeRequest.newRecommissioningStart),
                      },
                      {
                          labelKey: 'is37.field.recommissioningEnd',
                          value: formatOptionalDate(is37ChangeRequest.newRecommissioningEnd),
                      },
                  ]
                : []) satisfies EntityInformation['details']),
            {
                labelKey: 'is37.changeRequest.field.comment',
                value: is37ChangeRequest.requestorComments,
            },
        ],
    };
};
