import { ArrowDownward, ArrowUpward, Redo, Undo } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import {
    ArrowDownRight,
    ArrowUpLeft,
    TableColumnPlusAfter,
    TableRowPlusAfter,
    TableRowPlusBefore,
    TableRowRemove,
} from 'mdi-material-ui';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useIntl } from 'react-intl';
import { PersonReadDTO, WdpPerformedTaskReadDTO, WdpVersionReadDTO, WdpWorkStepReadDTO } from '../../../../api/dto';
import { NEW_ENTITY_FIELD, Range, Spreadsheet, SpreadsheetProps } from '../../../../components/spreadsheet/Spreadsheet';
import {
    FieldMetadata,
    fieldsMetadata,
    GridMenu,
    GridMenuItem,
    GridMenuItemType,
    MenuButton,
    TableHeader,
    WdpDataKey,
    WdpIdKey,
    WdpTabsProps,
} from '../WdpInterfaces';
import { WdpActivityImportDialog } from './actions/WdpActivityImportDialog';
import { flattenMenu } from './menu/utils';
import { WdpTabMenu } from './menu/WdpTabMenu';
import { WdpTabMenuContextProvider } from './menu/WdpTabMenuContext';

export interface WdpTabBaseProps {
    menuEntries: MenuButton[];
    headers: TableHeader[];
    newRowSupplier: (wdp?: WdpVersionReadDTO) => Partial<WdpVersionReadDTO[WdpDataKey][number]>;
    metadata: FieldMetadata;
    spreadsheetProps?: Omit<SpreadsheetProps, 'data' | 'headers' | 'keyField' | 'setData' | 'disabled'>;
    customTypes?: WdpVersionReadDTO;
}

export interface WdpTabProps extends WdpTabBaseProps, Omit<WdpTabsProps, 'setSelectedTab'> {}

const getAllDescendantsOfTheNode = (tree: any[], node: Record<string, number>, idKey: string) => {
    const descendants: any[] = [];
    const collectDescendants = (currentNode: Record<string, number>) => {
        const idAttribute = currentNode[idKey];
        if (idAttribute !== undefined && idAttribute !== null) {
            const children = tree.filter((child) => child.parent?.[idKey] === idAttribute);
            children.forEach((child) => {
                descendants.push(child);
                collectDescendants(child);
            });
        }
    };
    if (!!node) collectDescendants(node);
    return descendants;
};

export const WdpTab: React.FC<WdpTabProps> = ({
    menuEntries: additionalMenuEntries,
    headers,
    wdp,
    setWdp,
    errors: allErrors,
    disabled,
    readOnly,
    cellLocation,
    setCellLocation,
    lastSubmittedWdp,
    newRowSupplier,
    metadata,
    attributes,
    spreadsheetProps,
    customTypes,
    focusedCell,
    handleUndoChanges,
    handleRedoChanges,
    spreadsheetHistory,
    setSpreadsheetHistory,
    historyEntryIndex,
    setHistoryEntryIndex,
    selectedTab,
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const idKey = metadata.idKey;
    const [selectionRanges, setSelectionRanges] = React.useState<Range[] | undefined>(undefined);
    const findNextIdForRows = useCallback(
        (rows: Record<typeof idKey, number>[]) => Math.max(-1, ...rows.map((row) => row[idKey])) + 1,
        [idKey]
    );
    const [nextId, setNextId] = useState(
        findNextIdForRows(wdp[metadata.dataKey as WdpDataKey] as any as Record<string, number>[])
    );
    const getNextId = useCallback(
        (rows: Record<typeof idKey, number>[]) => {
            // Robust to unexpected changes of IDs
            const nextAvailableId = Math.max(findNextIdForRows(rows), nextId);
            setNextId(nextAvailableId + 1);
            return nextAvailableId;
        },
        [findNextIdForRows, nextId, setNextId]
    );
    const dataKey: WdpDataKey = metadata.dataKey as WdpDataKey;
    const rowId = cellLocation ? cellLocation?.rowId : undefined;
    const firstSelection = selectionRanges?.length === 1 ? selectionRanges[0] : null;
    const selectionIndividualRow =
        selectionRanges !== undefined && cellLocation && cellLocation !== undefined
            ? !!firstSelection && firstSelection.first.row.idx === firstSelection.last.row.idx
            : cellLocation !== undefined
            ? true
            : null;
    const data = wdp[dataKey];
    const setData = useCallback(
        (callbackOrData: ((any: any) => any) | typeof data) => {
            setWdp((prevData: WdpVersionReadDTO) => {
                const newData = {
                    ...(prevData ?? {}),
                    [metadata.dataKey]:
                        typeof callbackOrData === 'function' ? callbackOrData(prevData[dataKey]) : callbackOrData,
                };
                const newIndex = historyEntryIndex + 1;
                setSpreadsheetHistory((prevHistoryData) => {
                    return [
                        ...prevHistoryData.slice(0, newIndex),
                        {
                            snapshot: newData,
                            tab: selectedTab,
                            cellLocation: { [selectedTab]: cellLocation },
                        },
                    ];
                });
                setHistoryEntryIndex(newIndex);
                return newData;
            });
        },
        [metadata, historyEntryIndex, spreadsheetHistory, cellLocation]
    ) as (callbackOrData: ((any: any) => any) | unknown[]) => void;

    const isEditable = !!attributes[metadata.dataKey]?.editable;

    const tabsWithHierarchy: WdpIdKey<WdpDataKey>[] = ['workStepsIdx', 'performedTasksIdx'];
    const isHierarchyTab = tabsWithHierarchy.includes(metadata.idKey as WdpIdKey<WdpDataKey>);

    const addRow = useCallback(
        //hierarchy assumption: adds the row at the same level
        (after: boolean, customValues: object = {}) => {
            setData((prevData: Record<string, number>[]) => {
                const newData = Array.isArray(prevData) ? [...prevData] : [];
                const newIdx = getNextId(newData);
                let newRow: Record<string, any> = {
                    [idKey]: newIdx,
                    [NEW_ENTITY_FIELD]: true,
                    ...newRowSupplier(wdp),
                    ...customValues,
                };
                if (isHierarchyTab) newRow = { ...newRow, parent: null };
                setCellLocation({ rowId: newRow[idKey], columnId: headers[0].name });
                if (prevData.length === 0) return [...newData, newRow];
                const focusedElement = prevData.find((t) => t?.[idKey] === rowId);
                // @ts-ignore
                const parentNode = prevData.find((child) => child[idKey] === focusedElement?.parent?.[idKey]);
                // @ts-ignore
                const siblings = prevData.filter((child) => child.parent?.[idKey] === parentNode?.[idKey]);
                if (!focusedElement) return after ? [...newData, newRow] : [newRow, ...newData];
                const siblingToSwapIndex = siblings.indexOf(focusedElement);
                const sibling = siblings[siblingToSwapIndex];
                const descendants = getAllDescendantsOfTheNode(prevData, sibling, idKey);
                const insertAt =
                    prevData.indexOf(after && descendants.length > 0 ? descendants[descendants.length - 1] : sibling) +
                    (after ? 1 : 0);
                // @ts-ignore
                if (parentNode !== undefined) newRow = { ...newRow, parent: { [idKey]: parentNode[idKey] } };
                return [...newData.slice(0, insertAt), newRow, ...newData.slice(insertAt, newData.length)];
            });
        },
        [setData, metadata, rowId]
    );

    const eraseParentData = (newParentNode: Record<string, any>) => {
        type KeysOfUnion<T> = T extends T ? keyof T : never;
        const attributesToPreserve = [
            'id',
            'parent',
            'description',
            'responsible',
            'workStepsIdx',
            'rpAssessment',
            'performedTasksIdx',
            '__newEntity',
            'expectedStartDate',
            'expectedEndDate',
        ] satisfies (KeysOfUnion<WdpWorkStepReadDTO | WdpPerformedTaskReadDTO> | typeof NEW_ENTITY_FIELD)[];

        return attributesToPreserve.reduce((acc: Record<string, any>, key) => {
            if (newParentNode[key] !== undefined) {
                acc[key] = newParentNode[key];
            }
            return acc;
        }, {});
    };

    const addChildRow = useCallback(() => {
        if (rowId !== undefined) {
            setData((prevData: Record<string, number>[]) => {
                // @ts-ignore
                const parentIndex = prevData.findIndex((row) => row[idKey] === rowId);
                const parent: any = prevData[parentIndex];
                // @ts-ignore //TODO distinguish existing rows and new ones by defining appropriate types in TS
                const parentId = parent ? parent[idKey] : null;
                const newData: any[] = Array.isArray(prevData) ? [...prevData] : [];
                const newIdx = getNextId(newData);
                const newRow: Record<string, any> = {
                    [idKey]: newIdx,
                    [NEW_ENTITY_FIELD]: true,
                    parent: { [idKey]: parentId },
                    ...newRowSupplier(),
                };
                setCellLocation({ rowId: newIdx, columnId: headers[0].name });
                newData.splice(parentIndex + 1, 0, newRow);

                newData[parentIndex] = {
                    ...eraseParentData(parent),
                    ...newRowSupplier(),
                }; //erase disabled parent data when creating a child
                return [...newData];
            });
        }
    }, [setData, metadata, rowId]);

    const removeRelations = (newData: Record<string, number>[], indexOfRemoved: number) =>
        Object.values(fieldsMetadata).forEach((fieldMetadata) =>
            wdp[fieldMetadata.dataKey].forEach((row) => {
                if (metadata.relationshipName) {
                    // @ts-ignore
                    row[metadata.relationshipName] = row[metadata.relationshipName]?.filter(
                        (element: Record<string, any>) =>
                            element[metadata.idKey] !== newData[indexOfRemoved][metadata.idKey]
                    );
                }
            })
        );

    const removeRow = useCallback(
        () =>
            setData((prevData: Record<string, number>[]) => {
                const newData = Array.isArray(prevData) ? [...prevData] : [];
                const dataAfterRemove = newData.filter((t) => t[idKey] !== rowId);
                const indexOfRemoved = newData.findIndex((t) => t[idKey] === rowId);
                removeRelations(newData, indexOfRemoved);
                const descendants = getAllDescendantsOfTheNode(newData, newData[indexOfRemoved], idKey);
                const newIndex = indexOfRemoved > 0 ? indexOfRemoved - 1 : 0;
                const filtered = dataAfterRemove.filter((item) => !descendants.includes(item));
                if (cellLocation) {
                    setCellLocation(
                        filtered.length > 0 ? { ...cellLocation, rowId: filtered[newIndex][idKey] } : undefined
                    );
                }
                return filtered;
            }),
        [setData, metadata, rowId]
    );

    const moveRow = useCallback(
        (direction: -1 | 1) => {
            if (rowId !== undefined) {
                setData((prevData: Record<string, number>[]) => {
                    const getElementToSwap = (tree: any[], node: Record<string, number | Record<string, number>>) => {
                        // @ts-ignore
                        const parentNode = tree.find((child) => child[idKey] === node.parent?.[idKey]);
                        const siblings = tree.filter((child) => child.parent?.[idKey] === parentNode?.[idKey]);
                        const siblingToSwapIndex = siblings.indexOf(node) + direction;
                        const sibling = siblings[siblingToSwapIndex];
                        if (direction === -1) return { element: sibling, isSibling: true };
                        const descendants = getAllDescendantsOfTheNode(tree, sibling, idKey);
                        return {
                            element: descendants.length > 0 ? descendants[descendants.length - 1] : sibling,
                            isSibling: descendants.length === 0,
                        };
                    };

                    const tempData = prevData.slice();
                    const i = tempData.findIndex((t) => t?.[idKey] === rowId);
                    const temp = tempData[i];
                    const descendants = getAllDescendantsOfTheNode(tempData, temp, idKey);
                    if (
                        i > -1 &&
                        i + direction >= 0 &&
                        i + direction < tempData.length &&
                        i + direction + descendants.length < tempData.length
                    ) {
                        const elementToSwapData = getElementToSwap(tempData, temp);
                        if (!elementToSwapData.element) return prevData;
                        tempData.splice(i, 1 + descendants.length);
                        const indexToInsert =
                            direction === 1
                                ? tempData.indexOf(elementToSwapData.element) + 1
                                : tempData.indexOf(elementToSwapData.element);
                        tempData.splice(indexToInsert, 0, ...[temp, ...descendants]);
                        return tempData;
                    }
                    return prevData;
                });
            }
        },
        [setData, metadata, rowId]
    );

    const changeRowLevel = useCallback(
        (direction: -1 | 1) => {
            if (rowId !== undefined) {
                setData((prevData: any[]) => {
                    const tempData = prevData.slice();
                    const i = tempData.findIndex((t) => t?.[idKey] === rowId);
                    const parentNode = tempData.find((child) => child[idKey] === tempData[i].parent?.[idKey]);
                    if (direction === 1 && parentNode) {
                        const temp = { ...tempData[i], parent: parentNode.parent };
                        const nodeDescendants = getAllDescendantsOfTheNode(tempData, tempData[i], idKey);
                        const siblingsAndDescendants = getAllDescendantsOfTheNode(tempData, parentNode, idKey);
                        const lastDescendant = siblingsAndDescendants.slice(-1)[0];
                        const elementToSwapIndex = tempData.indexOf(lastDescendant);
                        const elementToSwap = tempData[elementToSwapIndex];
                        if (elementToSwapIndex + 1 === tempData.length) {
                            tempData.splice(i, nodeDescendants.length + 1);
                            tempData.push(...[temp, ...nodeDescendants]);
                            return tempData;
                        }
                        tempData.splice(i, nodeDescendants.length + 1);
                        const newIndexOfElementToSwap = tempData.indexOf(elementToSwap);
                        const indexToInsert = newIndexOfElementToSwap === -1 ? i : tempData.indexOf(elementToSwap) + 1;
                        tempData.splice(indexToInsert, 0, ...[temp, ...nodeDescendants]);
                        return tempData;
                    }
                    if (direction === -1) {
                        const siblings = tempData.filter(
                            (child: { parent: Record<typeof idKey, any> }) =>
                                child.parent?.[idKey] === parentNode?.[idKey]
                        );
                        const siblingToSwapIndex = siblings.indexOf(tempData[i]) - 1;
                        const newParentNode = siblings[siblingToSwapIndex];
                        if (!newParentNode) return tempData;
                        tempData[i] = { ...tempData[i], parent: { [idKey]: newParentNode[idKey] } };
                        removeRelations(tempData, i);
                        tempData[tempData.indexOf(newParentNode)] = {
                            ...eraseParentData(newParentNode),
                            ...newRowSupplier(),
                        };
                        return tempData;
                    }
                    return prevData;
                });
            }
        },
        [setData, metadata, rowId]
    );

    const label = intl.formatMessage({ id: metadata.label });
    const isMenuReadOnly = ('assessmentsIdx' satisfies WdpIdKey<WdpDataKey>) === metadata.idKey || readOnly;
    const getMenuEntries = (): GridMenu => ({
        direction: 'row',
        divider: true,
        items: !isMenuReadOnly
            ? [
                  {
                      type: GridMenuItemType.Menu,
                      menu: {
                          direction: 'column',
                          items: [
                              {
                                  type: GridMenuItemType.Button,
                                  button: {
                                      label: intl.formatMessage(
                                          { id: 'wdp.spreadsheet.action.addBefore' },
                                          { element: label }
                                      ),
                                      Icon: TableRowPlusBefore,
                                      onClick: (customValues: object) => addRow(false, customValues),
                                      disabled: selectionIndividualRow === false,
                                  },
                              },
                              {
                                  type: GridMenuItemType.Button,
                                  button: {
                                      label: intl.formatMessage(
                                          { id: 'wdp.spreadsheet.action.addAfter' },
                                          { element: label }
                                      ),
                                      Icon: TableRowPlusAfter,
                                      onClick: (customValues: object) => addRow(true, customValues),
                                      disabled: selectionIndividualRow === false,
                                  },
                              },
                          ],
                      },
                  },
                  ...(isHierarchyTab
                      ? [
                            {
                                type: GridMenuItemType.Button,
                                button: {
                                    label: intl.formatMessage(
                                        { id: 'wdp.spreadsheet.action.addChild' },
                                        { element: label }
                                    ),
                                    Icon: TableColumnPlusAfter,
                                    onClick: addChildRow,
                                    disabled: !selectionIndividualRow,
                                },
                            } satisfies GridMenuItem,
                        ]
                      : []),
                  {
                      type: GridMenuItemType.Button,
                      button: {
                          label: intl.formatMessage({ id: 'wdp.spreadsheet.action.remove' }, { element: label }),
                          Icon: TableRowRemove,
                          onClick: removeRow,
                          disabled: !selectionIndividualRow,
                      },
                  },
                  {
                      type: GridMenuItemType.Menu,
                      menu: {
                          direction: 'column',
                          items: [
                              {
                                  type: GridMenuItemType.Button,
                                  button: {
                                      label: intl.formatMessage(
                                          { id: 'wdp.spreadsheet.action.moveUp' },
                                          { element: label }
                                      ),
                                      Icon: ArrowUpward,
                                      onClick: () => moveRow(-1),
                                      disabled: !selectionIndividualRow, // TODO: imperfect (e.g. if the element is the first/last sibling then should be disabled)
                                  },
                              },
                              {
                                  type: GridMenuItemType.Button,
                                  button: {
                                      label: intl.formatMessage(
                                          { id: 'wdp.spreadsheet.action.moveDown' },
                                          { element: label }
                                      ),
                                      Icon: ArrowDownward,
                                      onClick: () => moveRow(1),
                                      disabled: !selectionIndividualRow, // TODO
                                  },
                              },
                          ],
                      },
                  },
                  ...(isHierarchyTab
                      ? [
                            {
                                type: GridMenuItemType.Menu,
                                menu: {
                                    direction: 'column',
                                    items: [
                                        {
                                            type: GridMenuItemType.Button,
                                            button: {
                                                label: intl.formatMessage(
                                                    { id: 'wdp.spreadsheet.action.moveLevelUp' },
                                                    { element: label }
                                                ),
                                                Icon: ArrowUpLeft,
                                                onClick: () => changeRowLevel(1),
                                                disabled: !selectionIndividualRow,
                                            },
                                        },
                                        {
                                            type: GridMenuItemType.Button,
                                            button: {
                                                label: intl.formatMessage(
                                                    { id: 'wdp.spreadsheet.action.moveLevelDown' },
                                                    { element: label }
                                                ),
                                                Icon: ArrowDownRight,
                                                onClick: () => changeRowLevel(-1),
                                                disabled: !selectionIndividualRow,
                                            },
                                        },
                                    ],
                                },
                            } satisfies GridMenuItem,
                        ]
                      : []),
                  ...additionalMenuEntries.map(
                      (entry) => ({ type: GridMenuItemType.Button, button: entry } satisfies GridMenuItem)
                  ),
                  {
                      type: GridMenuItemType.Menu,
                      menu: {
                          direction: 'column',
                          items: [
                              {
                                  type: GridMenuItemType.Button,
                                  button: {
                                      label: intl.formatMessage(
                                          { id: 'wdp.spreadsheet.action.undo' },
                                          { element: label }
                                      ),
                                      Icon: Undo,
                                      onClick: handleUndoChanges,
                                      disabled: historyEntryIndex <= 0,
                                  },
                              },
                              {
                                  type: GridMenuItemType.Button,
                                  button: {
                                      label: intl.formatMessage(
                                          { id: 'wdp.spreadsheet.action.redo' },
                                          { element: label }
                                      ),
                                      Icon: Redo,
                                      onClick: handleRedoChanges,
                                      disabled: historyEntryIndex + 1 >= spreadsheetHistory.length,
                                  },
                              },
                          ],
                      },
                  },
              ]
            : [],
    });

    const menu = getMenuEntries();

    const handleImport = (participants: PersonReadDTO[]) =>
        participants.map((person) => ({ unknown: false, person })).forEach((row) => addRow(true, row));

    const tabErrors = useMemo(
        () => allErrors?.[metadata.dataKey as WdpDataKey] as FieldErrors<WdpVersionReadDTO[WdpDataKey]> | undefined,
        [allErrors, metadata.dataKey]
    );
    const errorHighlights = useMemo(
        () =>
            lastSubmittedWdp !== undefined && Array.isArray(tabErrors)
                ? tabErrors?.flatMap((rowErrors, rowIndex) =>
                      headers.flatMap(({ name }) => {
                          const otherDataKey = metadata.dataKey as WdpDataKey;
                          const value = (rowErrors as any)[name] as FieldError | undefined;
                          const submittedRowRecord: object = lastSubmittedWdp[otherDataKey][rowIndex];
                          const optionalRowId = (
                              submittedRowRecord as Record<typeof metadata.idKey, number> | undefined
                          )?.[metadata.idKey];
                          const stillExists = (wdp[metadata.dataKey as WdpDataKey] as any[]).some(
                              (v) => v[metadata.idKey] === optionalRowId
                          );
                          return value !== undefined && value?.message && optionalRowId !== undefined && stillExists
                              ? [
                                    {
                                        rowId: optionalRowId,
                                        columnId: name,
                                        borderColor: theme.palette.error.main,
                                    },
                                ]
                              : [];
                      })
                  ) ?? []
                : [],
        [
            tabErrors,
            lastSubmittedWdp,
            ...Object.values(fieldsMetadata).map(({ dataKey: childDataKey }) => wdp[childDataKey]),
        ]
    );

    // No state management here, this is only to control the focus
    const containerRef = useRef();
    useEffect(() => {
        if (containerRef.current) {
            if (focusedCell) {
                const { rowIdx, columnIdx } = focusedCell;
                setTimeout(() => {
                    const element = document.querySelector(
                        `[data-cell-rowidx="${rowIdx + 1}"][data-cell-colidx="${columnIdx + 1}"]`
                    );
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                });
            }
        }
    }, [containerRef, focusedCell]);

    return (
        <>
            <WdpTabMenuContextProvider>
                {!readOnly && isEditable && (
                    <>
                        <WdpTabMenu menu={menu} disabled={disabled} sx={{ m: { xs: 1, sm: 2 } }} />
                        <WdpActivityImportDialog
                            activities={wdp.dimr?.rpAssessments?.map(({ activity }) => activity) ?? []}
                            onImport={handleImport}
                        />
                    </>
                )}
                <Box>
                    <Box ref={containerRef} sx={{ overflowX: 'auto' }}>
                        <Spreadsheet
                            headers={headers}
                            data={data}
                            setData={setData}
                            keyField={metadata.idKey}
                            disabled={!!disabled || !!readOnly || !isEditable}
                            wdp={wdp}
                            menuEntries={flattenMenu(menu)}
                            focusLocation={cellLocation}
                            onFocus={setCellLocation}
                            onSelection={setSelectionRanges}
                            headerHeight={75}
                            rowHeight={30}
                            addIdxHeader={true}
                            customTypes={customTypes}
                            highlights={errorHighlights}
                            stickyLeftColumns={!isHierarchyTab ? 1 : 2} // Sticky steps
                            {...spreadsheetProps}
                        />
                    </Box>
                </Box>
            </WdpTabMenuContextProvider>
        </>
    );
};
