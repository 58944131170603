/* eslint-disable @typescript-eslint/no-use-before-define */
import { Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createLockout, submitLockout, updateLockout } from '../../api/documents/lockouts';
import {
    DocumentActionDTO,
    DocumentType,
    LockoutContextReadDTO,
    LockoutReadDTO,
    LockoutStatusCode,
    TaskType,
} from '../../api/dto';
import { CommentList } from '../../components/comments/CommentList';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { InactiveActivityWarning } from '../../components/InactiveActivityWarning';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useDocumentCreationToast } from '../../hooks/useDocumentCreationToast';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import {
    makeLockoutDefaultValuesFromClonedLockout,
    makeLockoutDefaultValuesFromLinkedActivity,
    makeLockoutValues,
    mapLockoutToUpdateDTO,
} from '../../lib/records/documents/lockout';
import { LockoutContentHeader } from '../../pages/documents/lockout/LockoutContentHeader';
import { LockoutPageData } from '../../pages/documents/lockout/LockoutPageData';
import { LockoutPageVariant } from '../../pages/documents/lockout/LockoutPageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useFormRules } from '../rule/useFormRules';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { lockoutFormSchema } from '../schemas/documents/lockout';

interface LockoutFormProps {
    data: LockoutPageData;
    rules: PreciseRulesDTO<LockoutContextReadDTO>;
    refreshData: () => void;
}

export const LockoutForm: React.FC<LockoutFormProps> = ({ data: pageData, rules, refreshData }) => {
    const { withDispatchTaskEvent } = useTaskContext();
    const intl = useIntl();
    const { variant } = pageData;
    const navigate = useNavigate();

    const defaultValues = useMemo(
        () =>
            pageData.variant === LockoutPageVariant.READ
                ? makeLockoutValues(pageData.lockoutData)
                : pageData.variant === LockoutPageVariant.CLONE
                ? makeLockoutDefaultValuesFromClonedLockout(pageData.lockoutData)
                : makeLockoutDefaultValuesFromLinkedActivity(pageData.activityData, pageData.lockoutType),
        [pageData]
    );

    const tasks = useTasks(
        pageData.variant === LockoutPageVariant.READ
            ? {
                  documentTypes: [DocumentType.LOK],
                  documentId: pageData.lockoutData.id,
                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) =>
                      handleFormSaveError(response, { validate: true, submit: false }),
                  refreshData,
              }
            : undefined
    );
    const context: LockoutContextReadDTO | null = useMemo(
        () =>
            rules.context !== null
                ? {
                      ...rules.context,
                      documentAction: variant !== LockoutPageVariant.READ ? DocumentActionDTO.CREATE : null,
                      task: tasks.currentTask?.type?.type ?? null,
                  }
                : null,
        [rules.context, tasks.currentTask]
    );
    const { form, attributes } = useFormRules<LockoutReadDTO>({
        formProps: {
            mode: 'onSubmit',
            defaultValues: {
                ...defaultValues,
            },
        },
        rules,
        originalValues: defaultValues,
        context: context as any,
    });
    const { handleSubmit, clearErrors, formState, watch } = form;

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.LOK);

    const sendDocumentCreationToast = useDocumentCreationToast('lockout.name');

    const { mutate: saveLockout, isLoading: isSaveLoading } = useMutation(
        async ({ data: formData, submit = false }: { data: any; submit?: boolean }) => {
            if (variant === LockoutPageVariant.READ) {
                const updateOrSubmit = submit ? submitLockout : updateLockout;
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateOrSubmit({
                    id: pageData.lockoutData.id,
                    data: mapLockoutToUpdateDTO(formData, false),
                    taskId: tasks.currentTask.id,
                });
            } else {
                if (submit) {
                    throw new Error(); // Must be created first
                }
                const result = await createLockout({ data: mapLockoutToUpdateDTO(formData, true) });
                return artificialDelay(result);
            }
        },
        {
            onSuccess: (result) => {
                if (variant === LockoutPageVariant.READ) {
                    refreshData();
                } else {
                    sendDocumentCreationToast();
                    navigate(
                        getRoute({
                            path: ROUTES.lockout.view,
                            params: { id: String(result.id) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );
    const { isDirty: formIsDirty } = formState;
    const isDirty = formIsDirty || variant !== LockoutPageVariant.READ; // Create/clone form is always dirty
    const isLoading = isSaveLoading || tasks.disabled;

    const isCompleteOnly =
        tasks.currentTask && [TaskType.LOCK_LOCKOUT, TaskType.UNLOCK_LOCKOUT].includes(tasks.currentTask?.type?.type);
    const isSavable = useIsSavableFromRules(attributes) && !isCompleteOnly;
    const isCompletable = tasks.isLoading || !!tasks.assignableTasks.length;

    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: !isLoading && isDirty,
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const showPerformPanel = !!attributes.lockExecutionDate?.visible;
    const showRemovePanel = !!attributes.unlockExecutionDate?.visible;
    const showSafetyWarning =
        pageData.variant !== LockoutPageVariant.READ ||
        ![LockoutStatusCode.FINISHED, LockoutStatusCode.CANCELLED, LockoutStatusCode.REJECTED].includes(
            pageData.lockoutData.status.code
        );

    const watchLocations = form.watch('lockoutLocations');
    const activityData =
        pageData.variant !== LockoutPageVariant.READ ? pageData.activityData : pageData.lockoutData.activity;
    const activityLocationIds = useMemo(
        () => activityData.locations.map((e) => e.location.id),
        [activityData.locations]
    );
    const showActivityLocationMismatchWarning =
        (watchLocations.length === 0 && activityLocationIds.length > 0) ||
        watchLocations.some((l) => !activityLocationIds.includes(l.id));

    const canClone = defaultValues.availableActions.canClone;

    const uiSchemaLockout = useMemo(
        () =>
            lockoutFormSchema(
                intl,
                showPerformPanel,
                showRemovePanel,
                showSafetyWarning,
                showActivityLocationMismatchWarning
            ),
        [intl, showPerformPanel, showRemovePanel, showActivityLocationMismatchWarning]
    );

    useEffect(() => {
        if (pageData.variant !== LockoutPageVariant.CREATE) {
            if (showPerformPanel && !pageData?.lockoutData.lockExecutionDate) {
                form.setValue('lockExecutionDate', new Date().toISOString());
            }

            if (showRemovePanel && !pageData?.lockoutData.unlockExecutionDate) {
                form.setValue('unlockExecutionDate', new Date().toISOString());
            }
        }
    }, [form, showPerformPanel, showRemovePanel]);

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <LockoutContentHeader
                        data={pageData}
                        editable={isSavable || isCompletable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === LockoutPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        context={context}
                                        isDirty={formIsDirty}
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...(variant === LockoutPageVariant.CREATE ||
                                        variant === LockoutPageVariant.CLONE
                                            ? COMMON_DOCUMENT_BUTTONS.CREATE
                                            : COMMON_DOCUMENT_BUTTONS.SAVE)}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveLockout({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                        important={!tasks.currentTask}
                                    />
                                )}
                                {pageData.variant !== LockoutPageVariant.READ && pageData.activityData !== null ? (
                                    <>{!pageData.activityData.isActive && <InactiveActivityWarning />}</>
                                ) : null}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {variant === LockoutPageVariant.READ && canClone && (
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.CLONE}
                                        onClick={() => {
                                            window.open(
                                                getRoute({
                                                    path: ROUTES.lockout.clone,
                                                    params: { id: String(pageData.lockoutData.id) },
                                                })
                                            );
                                        }}
                                        disabled={formIsDirty}
                                        disablingReason={intl.formatMessage({ id: 'document.form.disabledUnsaved' })}
                                    />
                                )}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <LinkedDocumentsPanel
                            type={EntityType.Lockout}
                            lockout={
                                variant === LockoutPageVariant.CREATE
                                    ? { activity: pageData.activityData }
                                    : defaultValues
                            }
                            contentModifier={(content) => content.map((e) => ({ ...e, defaultExpanded: true }))}
                        />
                        <ValidationErrorsPanel schema={uiSchemaLockout} />
                        <FieldsAttributesContextProvider value={attributes}>
                            <FormGenerator rootElement={uiSchemaLockout} form={form} disabled={isLoading} />
                            {variant === LockoutPageVariant.READ && (
                                <WorkflowHistory documentType={DocumentType.LOK} documentId={pageData.lockoutData.id} />
                            )}
                            {variant === LockoutPageVariant.READ && (
                                <CommentList
                                    documentTypes={[DocumentType.LOK]}
                                    documentId={pageData.lockoutData.id}
                                    currentTask={tasks.currentTask}
                                    disabled={isLoading}
                                />
                            )}
                        </FieldsAttributesContextProvider>
                    </>
                }
                Drawer={
                    variant === LockoutPageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};
